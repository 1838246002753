@use "../../styles/_variables.scss" as vars;
@use "../../styles/_mixins.scss" as mix;

.hero-flex-layout {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 5rem;
  background-color: vars.$primary-color-lighter;
  color: #333;
  margin-bottom: -3rem;
  height: 80vh;

  @include mix.respond-to(vars.$breakpoint-lg) {
    padding: 2rem;
    margin-bottom: -2.5rem;
  }

  @include mix.respond-to(vars.$breakpoint-md) {
    padding: 1rem;
    flex-direction: column;
    justify-content: space-evenly;
  }

  @include mix.respond-to(vars.$breakpoint-sm) {
    padding: 1rem;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .hero__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;

    @include mix.respond-to(vars.$breakpoint-sm) {
      padding: 1rem 0;
    }

    .hero__title__intro {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0;

      @include mix.respond-to(vars.$breakpoint-md) {
        font-size: 1.2rem;
      }
    }

    .hero__title__main {
      font-size: 5rem;
      font-weight: 900;
      margin-bottom: 0;

      @include mix.respond-to(vars.$breakpoint-md) {
        font-size: 3rem;
      }

      .hero__title__main:first-of-type {
        margin-bottom: 0 !important;
      }
    }

    .hero_cta {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1rem;
      padding: 1rem 0;

      @include mix.respond-to(vars.$breakpoint-sm) {
        padding: 1rem 0;
      }

      .hero__cta__btn {
        background-color: vars.$primary-color;
        color: vars.$light-color;
        padding: 1rem;
        border-radius: 9px;
        width: 10rem;
        text-align: center;

        font-size: vars.$font-size-md;
        font-weight: 700;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: vars.$secondary-color;
          color: vars.$light-color;
        }
      }
    }
  }

  .hero__image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 40%;
    z-index: 2;

    @include mix.respond-to(vars.$breakpoint-md) {
      height: 100px;
    }

    img {
      position: relative;
      width: 50%;
      min-width: 200px;
      max-width: 200px;
      bottom: -18rem;
      object-fit: contain;

      @include mix.respond-to(vars.$breakpoint-lg) {
        min-width: 200px;
        bottom: -12rem;
      }

      @include mix.respond-to(vars.$breakpoint-md) {
        min-width: 100px;
        max-width: 100px;
        left: 100%;
        bottom: -12rem;
      }

      @include mix.respond-to(vars.$breakpoint-sm) {
        bottom: -10rem;
      }
    }
  }

  .hero__wave {
    width: 100%;
    z-index: 1;

    .hero__wave__svg {
      position: absolute;
      z-index: 1;
    }
  }
}

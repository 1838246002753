@use "variables" as vars;
@use "mixins" as mix;
@use "_globals.scss" as globals;

body.light {
  @include mix.theme(vars.$light-theme);
}

body.dark {
  @include mix.theme(vars.$dark-theme);
}

body {
  color: var(--text);
  background-color: var(--background-color) !important;
  transition: background-color 0.3s ease-in-out;
}

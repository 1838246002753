@use "../../styles/_variables" as vars;
@use "../../styles/_mixins" as mix;
@use "./Bar";

.nav-bar {
  background-color: vars.$primary-color-lighter;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: sticky;
  z-index: 9999;

  @include mix.respond-to(vars.$breakpoint-sm) {
    padding: 1rem;
  }

  .nav-bar__left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    .nav-bar__logo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      gap: 0.5rem;

      color: vars.$text-color-base;
      &:hover {
        color: vars.$secondary-color;
      }

      img {
        width: 50px;
        height: auto;
      }

      .nav-bar__logo__text {
        font-size: vars.$font-size-lg;
        font-weight: vars.$font-weight-bolder;
        margin-bottom: 0;

        @include mix.respond-to(vars.$breakpoint-sm) {
          font-size: vars.$font-size-md;
        }
      }
    }
    .nav-bar__link {
      color: vars.$text-color-base;
      &:hover {
        color: vars.$secondary-color;
      }
    }
  }

  .nav-bar__right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    .nav-bar__links li {
      color: vars.$text-color-base;
      font-weight: 600;
      font-size: vars.$font-size-md;
      &:hover {
        color: vars.$secondary-color;
      }
    }
    &.show {
      opacity: 1;
      visibility: visible;
    }
    .nav-bar__extra {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: nowrap;
      .nav-bar__extra ul li {
        margin: 0 0.5rem;
        color: vars.$text-color-base;
        &:hover {
          color: vars.$secondary-color;
        }
      }
    }
  }

  .nav-bar__right ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0 0.5rem;
      font-size: vars.$font-size-md;
      font-weight: 600;
    }
  }

  &.show-nav {
    .nav-bar__right {
      display: flex !important;
    }
  }
}

@media (max-width: 768px) {
  .nav-bar {
    .nav-bar__right {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: vars.$primary-color-lighter;
      z-index: 999;

      ul {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          margin: 1rem 0.5rem;
        }
      }
    }

    .nav-bar__right.show {
      opacity: 0.9;
      visibility: visible;

      .navbar .nav-bar__right ul {
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
      }
    }

    .menu-icon {
      display: flex;
    }

    .menu {
      display: block;
      z-index: 1000;
    }
  }
}

// Styles for larger screens (desktop)
@media screen and (min-width: 769px) {
  .nav-bar {
    .menu {
      display: none;
    }

    .nav-bar__left,
    .nav-bar__right {
      display: flex;
      flex-direction: row;
      opacity: 1;
      visibility: visible;
    }
  }
}

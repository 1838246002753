@use "sass:color";

//COLORS
$primary-color: #3f51b5;
$primary-color-light: color.adjust($primary-color, $lightness: 20%);
$primary-color-lighter: color.adjust($primary-color, $lightness: 35%);
$secondary-color: #00897b;
$light-color: #f5f5f5;

$primary-color-transparent: rgba(
  $primary-color,
  0.5
); // Semi-transparent version

$text-color-base: #333;
$text-color-base-dark: #c0c0c0;
$nav-bar-background-color: #fff;

// LIGHT MODE

$light-theme: (
  primary: $primary-color-lighter,
  secondary: $secondary-color,
  text: $text-color-base,
  background-color: #fff,
);

// DARK MODE
$dark-theme: (
  primary: $primary-color-lighter,
  secondary: $secondary-color,
  text: $text-color-base-dark,
  background-color: #181818,
);

// FONT SIZES
$font-size-xs: 0.8rem;
$font-size-sm: 1rem;
$font-size-md: 1.2rem;
$font-size-lg: 1.5rem;
$font-size-xl: 2rem;
$font-size-xxl: 2.5rem;

// FONT WEIGHTS
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;

// BREAKPOINTS
$breakpoint-xs: 320px;
$breakpoint-sm: 480px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;

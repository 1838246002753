@use "../../styles/_variables.scss" as vars;

.custom-shape-divider-bottom-1705611298 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1705611298 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 75px;
}

.custom-shape-divider-bottom-1705611298 .shape-fill {
    fill: vars.$primary-color-lighter;
}
@use "sass:map";

//THEME COLORS
@mixin theme($theme) {
  --primary: #{map.get($theme, primary)};
  --secondary: #{map.get($theme, secondary)};
  --text: #{map.get($theme, text)};
  --background-color: #{map.get($theme, background-color)};
}


// LAYOUT
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@mixin section-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 4rem;

  @media screen and (min-width: 1800px) {
    max-width: 1400px;
  }
}
// MEDIA QUERIES
@mixin respond-to($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

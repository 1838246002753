@use "./_mixins" as mix;
@use "./_variables" as vars;

// CONTAINER
.container {
  max-width: 1200px;
  margin: 0 auto;
  /* Other container styles */
}

// FLEXBOX
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* Other flexbox styles */
}

// GRID
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  /* Other grid styles */
}

// MARGIN
.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-5 {
  margin-top: 5rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-3 {
  margin-bottom: 3rem;
}

.margin-bottom-5 {
  margin-bottom: 5rem;
}

// PADDING

.nav-bar-padding-top{
  padding-top: 4.23rem !important;
}
.padding-top-1 {
  padding-top: 1rem;
}

.padding-top-3 {
  padding-top: 3rem;
}

.padding-top-5 {
  padding-top: 5rem;
}

.padding-bottom-1 {
  padding-bottom: 1rem;
}

.padding-bottom-3 {
  padding-bottom: 3rem;
}

.padding-bottom-5 {
  padding-bottom: 5rem;
}

// TEXT 
.section__title {
  font-size: vars.$font-size-xxl;
  font-weight: 700;
  margin-bottom: 3rem;
  text-align: left;
}






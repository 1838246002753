@use "sass:color";

.cookie-banner {
  position: fixed;
  bottom: 20px;
  left: 20px;
  max-width: 360px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  z-index: 1000;
  animation: slide-in 0.5s ease-out;

  // For dark mode support
  .dark-theme & {
    background-color: rgba(28, 28, 30, 0.9);
    color: #f5f5f7;
  }

  .cookie-content {
    padding: 16px 20px;

    p {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 16px;
      color: #333;

      .dark-theme & {
        color: #f5f5f7;
      }
    }

    .cookie-buttons {
      display: flex;
      gap: 10px;

      button {
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &.btn-accept {
          background-color: #3f51b5;
          color: white;

          &:hover {
            background-color: color.adjust(#3f51b5, $lightness: -10%);
          }
        }

        &.btn-decline {
          background-color: transparent;
          color: #666;
          border: 1px solid #ccc;

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }

          .dark-theme & {
            color: #ccc;
            border-color: #555;

            &:hover {
              background-color: rgba(255, 255, 255, 0.05);
            }
          }
        }
      }
    }
  }
}

// Animation for smooth entry
@keyframes slide-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

// Media query for mobile devices
@media (max-width: 480px) {
  .cookie-banner {
    max-width: calc(100% - 40px);
    bottom: 10px;
    left: 10px;
    right: 10px;

    .cookie-content {
      padding: 14px 16px;

      .cookie-buttons {
        flex-direction: column;
      }
    }
  }
}

.cookie-settings-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 999;
  
  button {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #ccc;
    padding: 12px;
    border-radius: 50%;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    
    &:hover {
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
    
    .dark-theme & {
      background-color: rgba(50, 50, 50, 0.8);
      color: #f5f5f7;
      border-color: #555;
      
      &:hover {
        background-color: rgba(50, 50, 50, 1);
      }
    }
  }
}

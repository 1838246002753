$font-family-base: 'Poppins', sans-serif;

// Import Bootstrap's styles
@use 'bootstrap/scss/bootstrap' as bootstrap;

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 400;
  color: #333;
  overflow-x: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif
}
